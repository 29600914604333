import React from "react"
import PageTransition from "gatsby-v2-plugin-page-transitions"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <PageTransition>
    <Layout lang={"pl"}>
      <Seo
        lang={"pl"}
        title={`404: Not found`}
      />
      <h1>notfound.header</h1>
      <p>notfound.description</p>
    </Layout>
  </PageTransition>
)

export default NotFoundPage